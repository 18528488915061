import React from "react"
import Layout from "../components/layout"

export default function Contact() {
  return (
    <Layout>
      <div className="my-10 space-y-3 text-center flex flex-col justify-items-center">
        <h1 className="text-2xl font-bold mb-5">Impressum</h1>
        <p>Fam.Kapsalis Restaurant Delphi</p>
        <p>Hochstr.76 56112 Lahnstein (Germany)</p>
        <p>Tel. +49 (0) 262150606</p>
        <p>Umsatzsteuer-ID: DE 2208080215</p>
        <p>
          Aufsichtsbehörde: Stadtverwaltung Lahnstein (Gewerbe /Ordnungsamt){" "}
        </p>
        <p>Angaben zur Berufshaftpflicht-versicherung.</p>
        <p>Name und Sitz der Gesellschaft:</p>
        <p>
          Berufsgenossenschaft Nahrungsmittel und Gastgewerbe (BGN) Dynamostraße
          7-11 68165 Mannheim Geltungsraum der Versicherung: Deutschland
        </p>
      </div>
    </Layout>
  )
}
